import { AuthToken } from '@/app/shared/auth/auth-token'
import { useErrorStore } from '@/app/shared/store/error'

const { store } = useErrorStore()

export function handleBadRequest(error) {
  if (error.response.data.non_field_errors) {
    return { contents: error.response.data.non_field_errors.join(), show: 3 }
  }
  if (Array.isArray(error.response.data.code)) {
    return { contents: error.response.data.code.join(), show: 3 }
  }
  if (error.response.data.file_specifications?.non_field_errors) {
    return {
      contents: error.response.data.file_specifications.non_field_errors.join(),
      show: 3
    }
  }
  if (error.response.data.verification?.non_field_errors) {
    return {
      contents: error.response.data.verification.non_field_errors.join(),
      show: 3
    }
  }

  return Object.entries(error.response.data || {}).map(([key, value]) => ({
    contents: value,
    field: key,
    show: 3
  }))
}

export function handleResponseError(error) {
  if (!error.response) return Promise.reject(error)

  switch (error.response.status) {
    case 400:
      error.response.errors = handleBadRequest(error)
      break
    case 401:
      AuthToken.set('', true)
      location.href = '/'
      break
    case 403:
      if (error.response.data.code === 'not_verified') {
        location.href =
          '/auth/user_waiting_approval?redirect=' +
          encodeURIComponent(window.location.pathname + window.location.search)
      }
      break
    case 500:
      store.dispatch('changeErrorState')
      break
  }
  error.response.inputErrors = error.response.data

  return Promise.reject(error)
}

export const STORAGE_KEYS = {
  LOCALE: 'locale',
  USER: 'user',
  USER_DELEGATOR: 'userDelegator'
}

export const HEADER_KEYS = {
  ACCEPT_LANGUAGE: 'Accept-Language',
  AUTHORIZATION: 'Authorization',
  DATAMAKER_LOGIN_AS: 'DATAMAKER-Login-As',
  DATAMAKER_WORKSPACE: 'DATAMAKER-Workspace'
}

export const TOKEN_PREFIX = 'Token'
export const DEFAULT_LANGUAGE = 'en'
